import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRouter = () => {
    const loginState = useSelector((state: any) => state.loginState);
    const isAuthenticated = loginState?.data?.token !== undefined;
    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

const CheckAuthRouter = () => {
    const loginState = useSelector((state: any) => state.loginState);
    const isAuthenticated = loginState?.data?.token !== undefined;
console.log("private")
    if (isAuthenticated) {
        const newUrl = `${window.location.origin}/#/dashboard`;
        window.history.replaceState(null, "", newUrl);
        return <Navigate to="/dashboard" replace />;
    }

    return <Outlet />;
};

export { PrivateRouter, CheckAuthRouter };

